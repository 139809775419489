import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerBasic from "../components/banners/bannerBasic"

import Banner from "../images/banner.png"

export default function Contact() {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <BannerBasic
        img={Banner}
        title="Contact Us to Get a Quote or Request an Appointment"
      />
    </Layout>
  )
}
